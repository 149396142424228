.chart-box {
  background: #FFFFFF;
  box-shadow: 0px 2.42149px 9.68595px rgba(40, 41, 61, 0.08), 0px 24.2149px 38.7438px rgba(96, 97, 112, 0.24);
  border-radius: 36.3223px;
  padding: 38px;
}

.chart-box h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 100%;
  color: #1A1A1A;
}

.chart-box small {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #7E7E7E;

}

.chart-box p {
  font-weight: 700;
  font-size: 12px;
}

.green {
  color: #00AC4F;
  margin-right: 5px;
}

.yellow {
  color: #FECB17;
  margin-right: 5px;
}

.blue {
  color: #4763E4;
  margin-right: 5px;
}

.header_sec {
  background: #FFFFFF;
  padding: 28px 55px 20px 27px;
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
  height: 91px;
 position: fixed;
 width: 100%;
 z-index:99;
}

.right-side-section {
  position: relative;
}

.header_sec h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  padding-left: 40px;
  color: #000000;
}

.noti-msg {
  margin: 0px 20px;
  position: relative;
  cursor: pointer;
}

.noti-msg-white {
  margin: 0px 20px;
  position: relative;
  display: flex;
  justify-content: end;
  top: -44px;
}

.notification {
  top: -22px;
  background-color: #D71919;
  color: #fff;
  border-radius: 50%;
  font-weight: 700;
  font-size: 14px;
  width: 22px;
  height: 22px;
  position: absolute;
  text-align: center;
  left: 10px;
}

.noti-msg-white .notification {
  left: 97.8% !important;
  top: -24px;
}

.profile-im {
  position: absolute;
  right: 20px;
}

/* .boxcss{
    background: #FFFFFF;
    box-shadow: 0px 2.42149px 9.68595px rgba(40, 41, 61, 0.08), 0px 24.2149px 38.7438px rgba(96, 97, 112, 0.24);
    border-radius: 36.3223px;
} */
.overview-card {
  background: #FFFFFF;
  box-shadow: 0px 2.42149px 9.68595px rgba(40, 41, 61, 0.08), 0px 24.2149px 38.7438px rgba(96, 97, 112, 0.24);
  border-radius: 36.3223px;
  height: 400px;
  padding: 24px;
  margin: 22px 0;
}

.plan-members {
  background: #FFFFFF;
  box-shadow: 0px 2.42149px 9.68595px rgba(40, 41, 61, 0.08), 0px 24.2149px 38.7438px rgba(96, 97, 112, 0.24);
  border-radius: 36.3223px;
  height: 400px;
  padding: 20px;
  margin: 22px 0;
}

/* .margin-sec {
  margin: 50px 42px 0px 50px;
} */
.logo-im {
  height: 70px;
}

.logo-im b {
  position: fixed;
}

.table-bg {
  background: #FFFFFF;
  /* 6 */
  padding: 0px 20px;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 30px;
  margin: 31px 0px;
  min-height: 530px;
}
.min-table-bg {
  background: #FFFFFF;
  /* 6 */
  padding: 0px 20px;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 30px;
  margin: 31px 0px;
  min-height: 340px;
}
.mosque-form button{
  margin-top: 16px !important;
}
.mosque-info-line h6{
  font-size: 13px;
}
.sidbaar-scroll {
  overflow: auto;
  overflow-x: hidden;
  max-height: 670px;
  position: fixed;
}

.sidbaar-scroll::-webkit-scrollbar {
  width: 4px;
  /* background-image: url("https://ik.imagekit.io/rzlzf40okya/elixir/Line.svg?updatedAt=1689592501360");
  background-repeat: no-repeat; */

}

.sidbaar-scroll::-webkit-scrollbar-thumb {
  background-image: url("https://ik.imagekit.io/rzlzf40okya/elixir/Line.svg?updatedAt=1689592501360");
  background-repeat: no-repeat;



}

.profile-scroll::-webkit-scrollbar {
  width: 4px;
}

.profile-scroll::-webkit-scrollbar-thumb {
  background: #9197B3;
  border-radius: 5px;
}

.profile-scroll {
  overflow: scroll;
  overflow-x: hidden;
  max-height: 539px;
  margin-bottom: 5px;
}

.notification-modal .modal-dialog {
  max-width: 400px;
  margin: 4.4rem 6.4rem 0rem auto;
}

.notification-modal .modal-content {
  border-radius: 20px;
}

.notification-modal-v1 .modal-dialog {
  max-width: 400px;
  margin: 3.7rem 20.5rem 0rem auto;
}

.notification-modal-v1 .modal-content {
  border-radius: 20px;
}

.profile-modal .modal-dialog {
  max-width: 460px;
  margin: 0rem 0rem 0rem auto;
  background: #181818;
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
  border-radius: 20px 0px 0px 20px;
  height: 100vh;
}

.profile-modal .modal-content {
  border-radius: 20px 0px 0px 20px;
  background: #181818;
  ;

}

.profile-modal .modal-body {
  background: #181818;
  margin-top: 0px;
border-radius: 20px 0px 0px 20px

}

.profile-modal h4 {
  color: #FFFFFF;
  margin-right: 10px;
  font-size: 26px;
    font-weight: 600;
}

.details-card {
  background: #F4F4F4;
  border-radius: 12px;
  height: auto;
  margin: 6px 20px;
  padding: 15px;
}

.details-card h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: #1A1A1A;
  margin-bottom: 15px;
}

.sent-msg {
  width: 300px;
}

.sent-msg h3 {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  height: 55px;
  color: #1A1A1A;
  min-width: 130px;
}

.message p {
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #1A1A1A;
}

.month-day {
  display: flex;
  justify-content: space-between;
}

.month-day h6 {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: #1A1A1A;
}

.custom-input {
  background: #FFFFFF;
  /* Inactive */
  border: 0px;
  border: 1px dashed #A0AEC0;
  padding: 0px 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  height: 37px;
}

.member_ship label {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #7E7E7E;
  margin-bottom: 10px;
}

.noti-heading h5 {
  font-weight: 700;
  font-size: 22px;
  color: #1A1A1A;
}

.monthly {
  color: #4763E4;
  background: #E3F2FD;
  border-radius: 4px;
  padding: 4px;
  margin-right: 10px;
}

.autoPay {
  color: #00AC4F;
  background: #E5F7ED;
  border-radius: 4px;
  padding: 4px;
}

.noti-heading h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  text-decoration-line: underline;
  color: #D0004B;
}

.border-bottom {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}

.name-profile {
  background: #9747FF;
  border-radius: 50%;
  color: #FFFFFF;
  width: 32px;
  height: 32px;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  padding-top: 5px;
}

.msg-notification {
  display: flex;
  position: relative;
}

.msg-notification p {
  margin-bottom: 0px;
  font-size: 13px;
}
.msg-notification p small{
  font-size: 10px;
}
.ml-4 {
  margin-left: 20px;
}

.dot {
  background: #D71919;
  width: 6px;
  height: 6px;
  border-radius: 20px;
}

.bblue {
  background: #9747FF;
  border-radius: 50%;
  color: #FFFFFF;
  width: 32px;
  height: 32px;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  padding-top: 5px;
}

.orange {
  background: #667799;
  border-radius: 50%;
  color: #FFFFFF;
  width: 32px;
  height: 32px;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  padding-top: 5px;
}

.gray {
  background: #F24822;
  border-radius: 50%;
  color: #FFFFFF;
  width: 32px;
  height: 32px;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  padding-top: 5px;
}

.profile-fixed-button{
  position: fixed;
    width: 25%;
    bottom: 0;
}
.modal-scroll {
  overflow: auto;
  overflow-x: hidden;
  max-height: 410px;
}

.modal-scroll::-webkit-scrollbar {
  width: 6px;
}

.modal-scroll::-webkit-scrollbar-thumb {
  background-image: url("https://ik.imagekit.io/rzlzf40okya/elixir/Line.svg?updatedAt=1689592501360");
  background-repeat: no-repeat;
}

.red-color {
  background: #FE1717;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.green-color {
  background: #00AC4F;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.gren {
  background: #00AC4F;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.red {
  background: #FE1717;
  border-radius: 50%;
  width: 12px;
  height: 12px;

}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 12px;
  overflow: visible !important;
  top: 6px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.add-event-form input {
  border: 1px solid rgba(196, 107, 72, 0.2);
  border-radius: 8px;
  cursor: pointer;
}

.add-event-form label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #323232;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  top: -4px;
  bottom: 4px;
  background: #FFFFFF;
  border: 1px solid #80CBC4;
  -webkit-transition: .4s;
  transition: .4s;
}

.tableCheckbox input:checked+.slider {
  background-color: #49C0B8;
}

.tableCheckbox input:focus+.slider {
  box-shadow: 0 0 1px #49C0B8;
}

input:checked+.slider {
  background-color: #80CBC4;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(17px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Add Mosque Modal starts */
.add-mosque-modal .modal-content {
  background: #FFFFFF;
  border: 1px solid #EFF0F7;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 20px;
  padding: 20px;
}

.add-mosque-modal .modal-body {
  padding: 0px;
}

.add-mosque-modal .add-mosque-modal-content div:nth-child(2) {
  font-weight: 700;
  font-size: 20px;
  color: #1A1A1A;
  margin-top: 20px;
}

.add-mosque-modal .add-mosque-modal-content div:nth-child(3) {
  font-weight: 400;
  font-size: 18px;
  color: #7E7E7E;
  margin: 20px 0;
}

.add-mosque-modal .add-mosque-modal-content button {
  background: #D0004B;
  border-radius: 800px;
  color: #FFFFFF;
  font-weight: 800;
  font-size: 18px;
  max-width: 232px;
  height: 60px;
  width: 100%;
  border: none;
  margin: 20px 0;
}

/* Add Mosque Modal ends */

/* admin profile links starts */
.admin-profile-links {
  position: relative;
  width: 65px;
  display: flex;
  align-items: center;
}

.admin-profile-links-ul {
  cursor: pointer;
  position: absolute;
  right: 18%;
  top: 75px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
  background: #FFFFFF;
  padding-left: 0;
  overflow: hidden;
  width: 130px;
}

.admin-profile-links-ul li {
  padding: 5px 10px;
}

.admin-profile-links-ul li a,
.admin-profile-links-ul li {
  font-weight: 500;
  font-size: 12px;
  color: #1A1A1A;
  list-style: none;
  text-decoration: none;
}

.admin-profile-links-ul li:hover {
  background: #f0f0f0;
}
.form_width{
  max-width: 90%;
  margin: 0 auto; 
}
/* admin profile links ends */

@media only screen and (max-width : 768px) {
  .notification-modal-v1 .modal-dialog {
    margin: 70px auto 0rem auto;
  }
  .noti-msg-white{
    top: -54px;
    right: 88px;
  }
  .notification-modal-v1 .modal-dialog {
    max-width: 330px;
}
.msg-notification {
  align-items: baseline;
  font-size: 13px;
}
.modal-backdrop{
  z-index: 1;
}
}

/* ipad */
@media screen and (min-width: 767px) and (max-width: 1024px) {
  .chart-box {
    padding: 15px !important;
}
.chart-box svg{
    width: 50px !important;
}
.chart-box h1 {
    font-size: 20px!important;
}
.chart-box small{
    font-size: 10px!important;
}
.header_sec h1{
  font-size: 20px;
}

}


@media (min-resolution: 90dpi) and (max-resolution: 100dpi){

}

/* 110% screen size */
@media (min-resolution: 130dpi) and (max-resolution: 140dpi){
  .profile-fixed-button{
    position: relative;
    width: 81%;
  }
  .profile-modal h4 {
    font-size: 20px;
  }
.overview-card{
  margin-top: 15px;
  max-height:380px;
  min-height: 380px;
  padding: 15px 24px 20px 24px;
}
.plan-members{
  margin-top: 15px;
  max-height:380px;
  min-height: 380px;
  padding: 15px 24px 20px 24px;
}
.chart-box h1 {
  font-size: 25px;
}
.chart-box{
  padding: 30px;
}
.overview-head {
  font-size: 20px !important;
}
.overview-disc {
  font-size: 14px !important;
}
.header_sec h1 {
  font-size: 24px;
}
.noti-heading h5 {
  font-size: 20px;
}
.details-card h5{
  font-size: 15px;
    margin-bottom: 14px;
}
}
/* 125% screen size */
@media (min-resolution: 140dpi) and (max-resolution: 150dpi){
  .overview-card{
    margin-top: 15px;
    max-height:380px;
    min-height: 380px;
    padding: 15px 24px 20px 24px;
  }
  .plan-members{
    margin-top: 15px;
    max-height:380px;
    min-height: 380px;
    padding: 15px 24px 20px 24px;
  }
  .chart-box h1 {
    font-size: 20px;
  }
  .chart-box{
    padding: 30px;
  }
  .overview-head {
    font-size: 18px !important;
  }
  .overview-disc {
    font-size: 13px !important;
  }
  .header_sec h1 {
    font-size: 20px;
  }
  .noti-heading h5 {
    font-size: 18px;
  }
  
  }
  /* 125% screen size */

/* admin mosque info */

.mosque-info-box {
  margin-top: 0px;
  min-height: 400px
}

.b-revenue-size{
  font-size: 14px;
}

.mosque-info-line:hover{
  background-color: #EEEEEE;
}

.mosque-info-line {
  border-bottom: solid 1px #EEEEEE;
}

.member-info-table {
  min-height: 533px;
  max-height: 500px;
}

.edit-mosque-button {
  align-items: center;
}

.revenue-graph .modal-content {
  border-radius: 29px;
  max-width: 900px;
}

.revenue-graph .modal-dialog {
  max-width: 900px !important;
}