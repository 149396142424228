.logout-modal .modal-content {
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #eff0f7;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  margin-top: 30%;
}

.logout-modal .modal-body .text {
  font-weight: 700;
  font-size: 20px;
  color: #1a1a1a;
  margin: 20px 0 12px 0;
}

.logout-modal .modal-header {
  justify-content: end;
}

.logout-modal .modal-header .close {
  border: none;
  background: transparent;
  margin: 10px;
}

.logout-modal .modal-header,
.logout-modal .modal-footer {
  border: none;
}

.logout-modal .modal-footer {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.logout-modal .modal-footer button {
  width: 197px;
  height: 60px;
  border-radius: 66px;
  font-weight: 600;
  font-size: 18px;
}

.logout-modal .modal-footer .cancel {
  background: #ffffff !important;
  color: #d0004b;
  border: 1px solid #d0004b;
}

.logout-modal .modal-footer .logout {
  background: #d0004b;
  border: 1px solid #d0004b;
  color: #ffffff;
}
.track-bg {
  background: rgba(211, 237, 234, 0.5);
  border-radius: 8px;
  padding: 20px;
}
