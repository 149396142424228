/* sidebar-css */
/* .sidebar_bg {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
    padding: 37px;
} */
.header-flex{
    display: flex;
    justify-content: space-between;
   max-width: 84%;
}
.black-header-flex{
    display: flex;
    justify-content: space-between;
   max-width: 94%;
}
.whiteBg{
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
    max-width: 250px;
    min-width: 250px;
    /* transition: all 500ms linear; */
    /* transition: width .15s linear .1s; */
    /* transition: all .6s ease-in-out; */
    /* transition: 0.5s; */
    transition: right 1s linear;
}
.blackBg{
    position: relative;
    background-color: #1A1A1A;
    box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
    max-width: calc(250px - 150px);
    min-width: calc(250px - 150px);
    /* transition: all 500ms linear; */
    /* transition: width .15s linear .1s; */
    /* transition: 0.5s; */
    transition: right 1s linear;

}
.wLogo{
color: #D0004B;
padding: 20px 35px;
text-align: left;
display:block;
justify-content: flex-start;
font-weight: 700;
font-size: 24px;
padding-left: 15px;
padding-bottom: 16px;
}
.bLogo{
    font-weight: 700;
    font-size: 24px;
    padding-left: 15px;
    padding-bottom: 16px;
    text-align: left;
   color: #fff;
   padding: 20px;
   display:flex;
   justify-content: center;
}
.wScroll{
max-width: 250px;
min-width: 250px;
overflow: auto;
overflow-x: hidden;
max-height: 670px;
position: fixed;
}
.wScroll::-webkit-scrollbar {
    width: 4px;
}
.wScroll::-webkit-scrollbar-thumb
{
    background-image: url("https://ik.imagekit.io/rzlzf40okya/elixir/Line.svg?updatedAt=1689592501360");
    background-repeat: no-repeat;
}
.bScroll{
max-width: calc(200px - 100px);
min-width: calc(200px - 100px);
overflow: auto;
overflow-x: hidden;
max-height: 670px;
position: fixed;
}
.bScroll::-webkit-scrollbar {
    width: 4px;
}
.bScroll::-webkit-scrollbar-thumb
{
    background-image: url("https://ik.imagekit.io/rzlzf40okya/elixir/Line.svg?updatedAt=1689592501360");
    background-repeat: no-repeat;
}
.wToggle{
    position: fixed;
    top: 50%;
    border-radius: 50%;
    z-index: 999;
    left: 184px;
    /* transition: transform .3s ease-in-out; */
    /* transition: all 500ms linear; */

}
.bToggle{
    position: fixed;
    top: 50%;
    border-radius: 50%;
    z-index: 999;
    left: 35px;
    /* transition: transform .3s ease-in-out; */
    /* transition: all 500ms linear; */
}
.bToggle svg{
    width: 70px;
}
/* .toggle-icon {

} */
.mobile-footer{
    display: none;
}
.grid-sec {
    display: flex;
    /* grid-template-columns: 20% 70%;
    gap: 5%; */
}

.activeClass {
    background: #D0004B;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
    border-radius: 200px;
    /* padding: 6px 11px; */
    fill: #fff;
    max-width: 190px;
    min-width: 190px;
}

.activeClass h5 {
    color: #fff !important;

}

.pink-card {
    background: linear-gradient(107.91deg, #FF7AAA 7.37%, #D0004B 95.19%);
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
    border-radius: 20px;
    text-align: center;
    list-style: none;
    padding: 17px;
    margin: 10px 25px;
}

.pink-card-white {
    background: linear-gradient(107.91deg, #FF7AAA 7.37%, #D0004B 95.19%);
    padding: 12px;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
    border-radius: 10px;
    margin: 16px 20px 29px;
}
.li-content-black{
    padding: 8px 0px;
}
.pink-card-white svg {
    margin: 0 auto;
    color: #fff;
}

.pink-card p {
    font-size: 12px;
}

.pink-card button {
    text-align: center;
    margin: 0 auto;
    box-shadow: 2px 4px 4px rgba(79, 42, 234, 0.17);
    min-width: 110px;
    min-height: 35px;

}

.li-content {
    display: flex;

}
.li-margin {
    padding: 9px 21px;
}

.li-content svg {
    margin-right: 7px;
}

.li-content h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #1A1A1A;
    text-decoration: none;
    margin-bottom: 0px;
}

.li-content h4 {
    font-size: 17px;
    margin-bottom: 0px;
}

.li-content a {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 5px 10px;
}

/* .logo-im {
    font-weight: 700;
    font-size: 24px;
    padding-left: 15px;
    padding-bottom: 16px;
} */

.elixir-header-fixed {
    background: #FFFFFF;
    box-shadow: 2px 2px 8px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    padding: 0px 130px;
    align-items: center;
    height: 85px;
}

.elixir-header-content ul {
    list-style: none;
    display: flex;
    padding-top: 0px;
    margin: 0;
    align-items: center;
}
.elixir-header-content li a {
    color: #212121;
    /* font-weight: 500; */
    font-size: 16px;
    padding: 0px 0px 21px;
    text-decoration: none;
}

li.activeClass {
    background: #D0004B;
    border-radius: 500px;
}

li.activeClass a {
    color: #fff;
}
.activeClassHeader a{
    color: #D0004B !important;
}
.fontIssue{
    font-weight: 500;
}

.register_buttton {
    border: 1px solid #D0004B;
    border-radius: 500px;
    width: 130px;
    height: 44px;
    display: flex;
    margin-right: 20px !important;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 700 !important;
}

.login_btn {
    background: #D0004B;
    border: 1px solid #D0004B;
    border-radius: 40px;
    width: 130px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 700 !important;
}

.lan-dp {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.menuitemmap{
    list-style: none;
    position: absolute;
    top: 30px;
    right: 0px;
    background: #fff;
    height: auto;
    border-radius: 4px;
    box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.16), 0px 0px 2px 0px rgba(40, 41, 61, 0.04);
}
.menuitemmap li{
    padding: 7px 10px !important;
    cursor: pointer;
}
.menuitemmap li:hover{
    color:#E71868;
}

.login_btn a {
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF !important;
    padding: 0 !important;
    text-decoration: none;
}

.register_buttton a {
    font-weight: 700;
    font-size: 18px;
    color: #D0004B !important;
    padding: 0 !important;
    text-decoration: none;
}

.register_buttton span img {
    width: 18px;
    margin-right: 5px;
}

.elixir-header-content li {
    padding: 10px 15px 10px;
    margin-right: 5px;
    min-width: max-content;
}

.elixir-header-content li img {
    /* width: 40px; */
    cursor: pointer;
}



.activeClassWhite {
    width: 100%;
    border-left: 4px solid#D0004B;
    background: #f2c4d4;
    padding: 25px;
    color: #D0004B;
    justify-content: center;
    height:75px;
}


.elixir-header-content {
    display: flex;
    align-items: center;
}
.footerSection{
    padding: 50px 0px;
}
/* .logo-div {
    cursor: pointer;
    background: #80CBC4;
    border-radius: 18px;
    color: #FFFFFF;
    color: #FFFFFF;
    width: 13%;
    min-width: 224px;
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
} */

/* .logo-div h6 {
    font-size: 25px;
    position: relative;
    margin: 0;
} */
.logo-div img{
    width: 100px;
    height: 85px;
    object-fit:cover;
}
.elixir-header-content button {
    border: 0px;
    background-color: transparent;
}

/* .header-modal{

    } */
    .btn-primary:hover {
        color: #fff;
        background-color: transparent;
        border-color: transparent;
    }

/* footer-css */
.logo-footer{
    width: 150px;
}
.footer-bg {
    background: #F4F6F8;
    padding: 30px;
}

.footerSection ul{
    list-style: none;
    display: flex;
    padding-left: 0px;
}
.footer-icn li{
    margin-right: 15px;
}
.footerSection h4{
    color:  #1A1A1A;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.00);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; 
}
.footerSection h6{
    color: #1A1A1A;
text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.00);
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
}
.footerSection p{
    color: #1A1A1A;
text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.00);
font-family: Raleway;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 29px;
padding-bottom: 40px;
}
.footerSection small{
    color:  #7E7E7E;
text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.00);
font-family: Raleway;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16.1px;
}
.footerSection span img{
    margin-right: 5px;
}
.padding-top{
    padding-top: 103px;
}
.footer-bg-icon ul {
    list-style: none;
    display: flex;
    padding-left: 0px;
}

.footer-text {
    display: grid;
    grid-template-columns: 400px 300px 300px;
    /* margin: 0 auto; */
}

.footer-text ul {
    padding-left: 0px;
}

.footer-text-section {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 75%;
    /* display: grid;
        grid-template-columns: 400px 300px 300px; */
}

.footer-text-section ul {
    padding-left: 0px;
}

.footer-text-section {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 400px 300px 300px;
}

.footer-text-section ul {
    padding-left: 0px;
}

.footer-input h3 {
    font-weight: 600;
    font-size: 24px;
    color: #212121;
    padding-bottom: 20px;
}

.footer-input input {
    background: rgba(219, 219, 219, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    width: 100%;
    height: 44px;
    margin-bottom: 15px;
    padding: 10px;
}

.footer-input input::placeholder {
    opacity: 0.8;
}

.footer-bottom p {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #212121;
    text-align: center;
    padding: 17px 0px 30px;
}

.footer-bg-icon li {
    margin-left: 50px;
}

.footer-bg-icon li:nth-child(1) {
    margin-left: 0px;
}

.footer-text li {
    padding: 10px 0px;
}

.footer-text li a {
    font-weight: 400;
    font-size: 16px;
    color: #212121;
}

.footer-text-section li {
    padding: 10px 0px;
}

.footer-text-section li a {
    font-weight: 400;
    font-size: 16px;
    color: #212121;
}

.contact-bg h6 {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px;
}

.contact-bg span {
    margin-right: 7px;
}

.contact-bg span img {
    width: 27px;
}

.contact-bg p {
    font-weight: 400;
    font-size: 14px;
}

.lan-dp {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.message-bg {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 213px;
    padding: 4px 10px 0px;
}

.message-bg h4 {
    font-weight: 700;
    font-size: 18px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #0336FF;

}

.contact-bg {
    width: 300px;
    margin-bottom: 25px;
}

.message-bg img {
    margin-left: 10px;
}

.modal_menu a {
    font-weight: 600;
    font-size: 22px;
    line-height: 44px;
    text-align: left;
    text-transform: capitalize;
    color: #212121;
}

.modal_menu_ul_li h6 {
    font-weight: 600;
    font-size: 22px;
    line-height: 44px;
    text-align: left;
    text-transform: capitalize;
    color: #212121;
    padding: 17px 29px 1px;
}

.modal_menu_ul_li ul {
    padding-left: 0rem;

}

.modal_menu_ul_li ul li {
    background-color: #FFFFFF;
    margin: 10px 0px;
    padding: 8px 18px;

}

.modal_menu_ul_li ul li i {
    font-size: 10px;
    padding: 0px 12px 11px;
    top: -3px;
    position: relative;
}

.modal_menu_ul_li {
    background: linear-gradient(90deg, #00ffee1a 0%, #0452c81a 100%);
    height: auto;
    padding-bottom: 5px;


}

.contact-bg {
    width: 300px;
    margin-bottom: 25px;
}

.message-bg img {
    margin-left: 10px;
}

.modal_menu a {
    font-weight: 600;
    font-size: 22px;
    line-height: 44px;
    text-align: left;
    text-transform: capitalize;
    color: #212121;
}

.modal_menu_ul_li h6 {
    font-weight: 600;
    font-size: 22px;
    line-height: 44px;
    text-align: left;
    text-transform: capitalize;
    color: #212121;
    padding: 17px 29px 1px;
}

.modal_menu_ul_li ul {
    padding-left: 0rem;

}

.modal_menu_ul_li ul li {
    background-color: #FFFFFF;
    margin: 10px 0px;
    padding: 8px 18px;

}

.modal_menu_ul_li ul li i {
    font-size: 10px;
    padding: 0px 12px 11px;
    top: -3px;
    position: relative;
}

.modal_menu_ul_li {
    background: linear-gradient(90deg, #00ffee1a 0%, #0452c81a 100%);
    height: auto;
    padding-bottom: 5px;

}

/* ham logo css starts for mobile */
.for-mobile-menu .modal .modal-dialog {
    margin: 0px;
}

.for-mobile-menu .modal button,
.menu-btn,
.menu-btn:focus {
    background: transparent;
    height: 50px;
    width: 50px;
    border: none;
    border-radius: 4px;
    box-shadow: none;
}

.for-mobile-menu .close {
    background-color: #000000 !important;
    border-radius: 50% !important;
    width: 28.33px !important;
    height: 28.33px !important;
    margin-left: auto;
    display: flex;
}

.for-mobile-menu .modal-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.profile-mobile {
    display: flex;
    align-items: center;
}

.profile-mobile .pic {
    width: 64px;
    height: 64px;
    background: #7E7E7E;
    border: 2px solid #7E7E7E;
    border-radius: 50%;
    margin-right: 20px;
}

.profile-mobile .pic img {
    width: 100%;
}

.profile-mobile .name {
    font-weight: 700;
    font-size: 20px;
    color: #1A1A1A;
}

.profile-mobile button {
    border: 1px solid #D0004B !important;
    border-radius: 800px !important;
    width: 100px !important;
    height: 30px !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #D0004B;
    margin-top: 10px;
}

.for-mobile-menu .modal-body ul {
    list-style: none;
    padding-left: 0px;
}

.for-mobile-menu .modal-body ul li {
    margin: 5px 0;
    padding: 10px;
}

.for-mobile-menu .modal-body ul li.activeClass {
    background-color: transparent;
    box-shadow: none;
}

.for-mobile-menu .modal-body ul li.activeClass a {
    color: #D0004B;
    font-weight: 700;
    font-size: 20px;
}

.for-mobile-menu .modal-body ul li a {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #1A1A1A;
    text-decoration: none;
}

/* ham logo css ends for mobile */

@media (max-width: 1200px) {
    .elixir-header-fixed {
        padding: 0px 20px;
    }

    .elixir-header-content li {
        padding: 10px 22px 10px;
    }

    .logo-div {
        min-width: 150px;
    }

    .logo-div h6 {
        font-size: 20px;
    }

    .elixir-header-content li a {
        font-size: 14px;
    }
}

@media (max-width: 1024px) {
    .footer-text-section {
        width: auto;
    }

    .footer-text {
        grid-template-columns: 350px 200px 200px;
    }
}


@media screen and (max-width: 890px) and (min-width: 767px) {
    .elixir-header-content li {
        padding: 10px;
    }

    .logo-div {
        min-width: 100px;
        height: 44px;
    }

    .register_buttton,
    .login_btn {
        width: 100px;
    }
}

@media (max-width: 520px) {

    .register_buttton,
    .login_btn {
        width: 84px;
        height: 32px;
    }

    .register_buttton {
        margin-right: 10px !important;
    }

    .register_buttton a,
    .login_btn a {
        font-size: 14px;
    }

    .elixir-header-fixed {
        padding: 10px;
    }

    .logo-div h6 {
        font-size: 14px;
    }

    .banner-grid-for-mobile {
        padding: 50px 20px !important;
    }

    .menu-btn,
    .menu-btn:focus {
        margin-right: 10px;
    }
}

@media (min-width: 767px) {
    .for-mobile-menu {
        display: none;
    }

}

/* footer-section */
.footer-sec {
    background: #1A1A1A;
    padding: 30px 50px 20px 10px;
}
.footer-grid{
    display: grid;
    grid-template-columns: 90px 90px 90px 90px 90px;
    width: 30%;
    margin: 0 auto;
    text-align: center;
}
.footer-sec p {
    text-align: center;
    font-size: 12px;
}

.footer-sec a {
    color: #fff;
    text-decoration: none;
}

.footer-sec a img {
    margin: 5px 10px;
}

.footer-sec li {
    color: #fff;
    list-style: none;
    text-align: center;
    /* padding: 0px 13px; */
}

.footer-sec h2 {
    color: #fff;
    font-family: inter;
    font-weight: 800;
}

.footer-sec p {
    color: #fff;
    margin-bottom: 0;
    margin-top: 20px;
}
@media (max-width: 767px) {
    /* .footer-text-section {
            grid-template-columns: auto;
        } */
        .footer-sec {
            padding: 21px 0px 20px 10px;
        }
        .footer-sec p {
            padding-top: 10px;
        }

        .footer-grid {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-between;
            margin: 10px 0px;
        }
        .footer-sec li{
            padding: 0px 5px;
          text-align: left;
        }
        .footer-sec a img {
            margin: 0px 10px;
            width: 26px;
        }
        .footer-sec a{
            font-size: 13px;
        }
    .elixir-header-content ul {
        display: none;
    }

    .elixir-header-fixed {
        padding: 20px 30px;
    }

    .footer-bg-icon li {
        margin-left: 5px;
    }

    .footer-text {
        grid-template-columns: auto;
    }

    .footer-text-section {
        grid-template-columns: auto;
    }

    .elixir-header-content button img {
        width: 30px;
    }

    .logo-div-mobile img {
        width: 70px;
    }
    .logo-div-mobile{
        background: none;
        color: #000000;
        min-width: max-content;
        position: relative;
    top: 15px;
    }

    .logo-div {
        display: none;
    }

    .logo-div-mobile h6 {
        font-weight: 700;
    }

    .menu-btn,
    .menu-btn:focus {
        margin-right: 5px;
    }


    .footer-logo {
        text-align: center;
        margin: 0 auto;
        display: block;
        width: 90px;
    }

    .footer-bg-icon img {
        width: 35px;
    }

    .footer-bg-icon ul {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
    }
.padding-top{
    padding-top: 0px;
}
.footerSection p {
    font-size: 14px;
    padding-bottom: 0px;
}
.footerSection h6 {
    font-size: 14px;
}
.footerSection a {
    font-size: 14px;
}
.footerSection ul{
    justify-content: center;
}
.footer-icn img{
    width: 30px;
}
.footerSection small{
    font-size: 10px;
    text-align: center;
}
    .footer-text ul {
        padding-left: 0px;
        display: grid;
        grid-template-columns: 50% 50%;
        border: 1px solid #00000012;
        margin-bottom: 0px;
        border-radius: 5px;
        padding-left: 13px;
    }

    .footer-text-section ul {
        padding-left: 0px;
        display: grid;
        grid-template-columns: 50% 50%;
        border: 1px solid #00000012;
        margin-bottom: 0px;
        border-radius: 5px;
        padding-left: 13px;
    }

    .footer-text-section li a {
        font-size: 14px;
    }

    /* .footer-text{
            text-align: center;
        } */
    .footer-text li a {
        font-size: 14px;
    }

    .footer-bg {
        padding: 20px 10px;
    }

    .footer-input {
        text-align: center;
    }

    .footer-input h3 {
        padding-bottom: 15px;
        padding-top: 20px;
        font-weight: 600;
        font-size: 20px;
    }

    .modal_menu_ul_li h6 {
        font-size: 22px;
        line-height: 44px;
        color: #fff;
        padding: 0px 29px 1px;
    }
    .desktop-footer{
        display: none;
    }
    .mobile-footer{
        display: block;
    }
    .footerSection{
        padding: 0px 0px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .li-content a {
      padding: 5px 0px;
    }
    .li-content h5 {
      font-weight: 500;
      font-size: 12px;
    }
    .logo-im {
        font-size: 18px;
    }
    .pink-card{
        margin: 20px 14px;
    }
    .pink-card button{
        min-width: 105px !important;
    }
    .pink-card p {
        font-size: 12px;
    }

  }
