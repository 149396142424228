.add_form {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 30px;
  height: auto;
  padding: 45px;
}
.add_form h2 {
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #1a1a1a;
  margin-bottom: 15px;
}

.plan-modal {
  border: 0.5px solid #d0004b;
  border-radius: 50px;
  width: 300px;
  padding-left: 0px;
  height: 54px;
  list-style: none;
}
.plan-modal li {
  padding: 13px;
}
.heading-2 {
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #1a1a1a;
  position: relative;
  top: -50px;
  width: 20%;
}
/* .input-width{
  max-width: 460px;
  min-width: 460px;
  margin-right: 40px;
} */
.input-width{
  font-size: 13px;
}