.ant-checkbox+span {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
}

.anticon svg {
    display: inline-block;
    width: 10px;
    margin-bottom: 5px;
}

.ant-btn-default {
    background-color: #f5f5f5;
    border: unset;
    height: 37px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}
