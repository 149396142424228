.blog-card {
  background: #ffffff;
  border: 1px solid #eff0f7;
  border-radius: 12px;
  padding: 14px;
}
.blog-card h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #1a1a1a;
  word-wrap: break-word;
}
.blog-card button {
  font-weight: 700;
  font-size: 14px;
  min-width: 100px;
  min-height: 32px;
}
.blog-card img {
  width: 100%;
}
.blog-profile {
  display: flex;
  align-items: center;
}
.blog-profile img {
  width: 34px;
  height: 34px;
  margin-right: 10px;
}
.blog-profile span {
  font-weight: 500;
  font-size: 14px;
  color: #7e7e7e;
}
.border-right {
  border-right: 1px solid #7e7e7e;
  height: 13px;
  margin: 4px 10px;
}
.blog-card:hover {
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 12px;
}
.details-card-blog {
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 30px;
  background: #fff;
  padding: 60px 150px;
}
.details-card-blog h2 {
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  color: #1a1a1a;
}
.input-boxx {
  height: 156px !important;
  background: #ffffff;
  width: 100%;
  display: block;
  border: 1px solid #eff0f7;
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 8px;
}
.user_data h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #7e7e7e;
}
.user_data b {
  color: #000;
}
.user_data h6 {
  font-weight: 500;
  font-size: 12px;
}
.chart-box-proress {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 30px;
  padding: 27px;
}
progress_bar {
  display: flex;
  justify-content: center;
}
.progress_bar label {
  font-size: 14px;
}
.progress_bar progress {
  height: 18px;
}
.progress_bar h6 {
  font-size: 14px;
}
.progress_skill {
  background: #d3edea;
  border-radius: 80px;

  width: 100%;
  height: 8px;
}
.skill_im {
  position: relative;
  top: -8px;
}
.skill1 {
  background: #00ac4f;
  border-radius: 80px;
  padding: 0;
  height: 10px;
  text-align: right;
}
.common-card {
  filter: drop-shadow(0px 1.99177px 7.96708px rgba(40, 41, 61, 0.08))
    drop-shadow(0px 19.9177px 31.8683px rgba(96, 97, 112, 0.24));
  height: 300px;
  border-radius: 30px;
  padding: 15px;
  background-color: #ffffff;
  margin: 20px 0px;
}

@media (max-width: 767px) {
  .details-card-blog h2 {
    font-size: 20px;
    line-height: 30px;
  
  }
 

}
