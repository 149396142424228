
input[type="radio"] {
    position: relative;
    width: 24px;
    height: 24px;
}

.cursor {
    cursor: pointer;
}

.radio-button {
    border: 1px solid #464646 !important;
}

.radio-button.checked {
    border: 1px solid #D0004B !important;
}

.radio-button-checked {
    background: #D0004B !important;
}

.et-icon {
    filter: invert(37%) sepia(98%) saturate(7497%) hue-rotate(201deg)
    brightness(102%) contrast(108%);
}

.et-icon-deep-grey path {
    fill: #464646 !important;
}

.custom-name-message-box-field {
    border: 1px solid #222222 !important;
    height: 40px !important;
    color: #222222 !important;
}
.custom-name-message-box-field::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #989898;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #989898;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c4c4c4;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:focus {
    color: #989898;
    background-color: #fff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(5, 130, 202, 0.25);
}

.form-control::placeholder {
    color: #989898;
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #f5f5f5;
    opacity: 1;
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
}

.special-tooltip {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    background: #fff !important;
    color: #222222 !important;
    box-shadow: 4px 4px 4px rgba(214, 214, 214, 0.25) !important;
    opacity: 1 !important;
    max-width: 400px;
}

.table {
    display: table;
    width: auto;
    /* border: 1px solid #666666; */
  }

  .table-row {
    display: table-row;
    width: auto;
    clear: both;
  }

  .table-col {
    float: left;
    display: table-column;
    width: 140px;
    border-bottom: 1px solid #EEEEEE;
    padding: 25px 20px;
    font-size: 14px;
  }
  .table_body:hover{
    background:rgb(126 126 126 / 16%);
  }
  .font-class{
    font-weight: 600;
font-size: 16px;
  }
  .pdf-btn{
    border: 1px solid #D0004B;
    color: #D0004B;
filter: drop-shadow(0px 2px 6px rgba(19, 18, 66, 0.07));
border-radius: 820px;
  }
  .green__{
    width: 16px;
height: 16px;
border-radius: 50%;
background-color: #00AC4F;
  }
  .redd{
    width: 16px;
height: 16px;
border-radius: 50%;
background: #FE1717;
  }
  .yellow__{
    width: 16px;
height: 16px;
border-radius: 50%;
background: #FECB17;
  }
